import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '@components/pageMeta';
import ModuleZone from '~moduleZone';
import Layout from '@components/layout';
import Container from '@components/financeForm/businessHomeLoans/container';
import FastContainer from '@components/financeForm/fastApproval/fastProviderContainer';

const PageTemplate = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { modules, seo, reverseHeader, hideFromIndex } = page || {};
  const { search } = useLocation();

  //grab the utm_source from the url
  const urlParams = new URLSearchParams(search);
  const utmSource = urlParams.get('utm_source');

  return (
    <Layout reversedHeader={reverseHeader}>
      <PageMeta {...seo} hideFromIndex={hideFromIndex} />
      {modules && <ModuleZone {...modules} />}
      <Container data={data} utmSource={utmSource} />
      <FastContainer data={data} utmSource={utmSource} />
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityModularPage(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      hideFromIndex
      modules {
        ...PageModules
      }
      reverseHeader
    }
    sanityHomeLoans {
      contactMethodDropdownOptions
      fundingDropdownOptions
      marketValueDropdownOptions
      regionDropdownOptions
      requiredByDropdownOptions
      requiredForDropdownOptions
      propertyTypeDropdownOptions
    }
    sanityBusinessLoans {
      contactMethodDropdownOptions
      financeTypeOptions
      industryDropdownOptions
      preferredPaymentDropdownOptions
      regionDropdownOptions
      requiredByDropdownOptions
      solutionTypeOptions
    }
    sanityFastLoans {
      alreadyPurchasedOptions
      businessClearCreditOptions
      contactMethodDropdownOptions
      directorsClearCreditOptions
      directorsPropertyOwners
      financeRequiredBy
      gstFundedOptions
      industryOptions
      isDirectorOptions
      legalStructureOptions
      positionOptions
      regionDropdownOptions
      repaymentOptions
      typeOfAssetOptions
      yearsOfOperationOptions
    }
  }
`;
